export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 80],
  colors: {
    mainBackground: "#fdfdfd",
    mainForeground: "#303030",
    secondaryForeground: "#999999",
    blue: "#1870db",
    blueDarker: "#0765d9",
    lightgray: "#f6f6ff",
    lightText: "#575757",
    white: "#ffffff",
  },
  space: [0, 4, 8, 16, 32, 64, 128, 144, 256],
  fonts: {
    navigation: "lato, system-ui, sans-serif",
    body: "poppins, system-ui, sans-serif",
    heading: "poppins, system-ui, sans-serif",
    link: "poppins, system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    heading: 700,
    headingSecondary: 200,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
    large: 1.8,
  },
  shadows: {
    small: "0 0 4px rgba(0, 0, 0, .125)",
    large: "0 0 24px rgba(0, 0, 0, .125)",
  },
  variants: {
    link: {
      fontFamily: "poppins, system-ui, sans-serif",
      color: "mainForeground",
    },
    nav: {
      display: ["none", "flex"],

      px: 3,
      fontFamily: "body",
      color: "mainForeground",
      fontSize: 1,
      textTransform: "uppercase",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "mainForeground",
      },
    },
    navBold: {
      display: ["none", "flex"],

      px: 3,
      fontFamily: "body",
      fontWeight: "bold",
      color: "mainForeground",
      fontSize: 1,
      textTransform: "uppercase",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "mainForeground",
      },
    },
    navDropdown: {
      width: "100%",
      fontFamily: "body",
      color: "secondaryForeground",
      fontSize: 3,
      textTransform: "uppercase",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "mainForeground",
      },
    },

    goBack: {
      px: [4, 0],
      fontFamily: "navigation",
      color: "secondaryForeground",
      fontSize: [2, 1],
      textTransform: "uppercase",
      textDecoration: "none",
      ":hover,:focus,.active": {
        color: "mainForeground",
      },
    },
  },
  text: {
    heading: {
      color: "mainForeground",
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    display: {
      color: "mainForeground",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: [5, 6, 7],
    },
    caps: {
      color: "mainForeground",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      textTransform: "uppercase",
      letterSpacing: "0.1em",
    },
    content: {
      fontSize: 12,
      color: "lightText",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      marginBottom: 16,
    },
    awards: {
      fontSize: 12,
      color: "lightText",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      marginBottom: 2,
    },
    smallprint: {
      color: "lightText",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 10,
    },
    navSmallprint: {
      textTransform: "uppercase",
      color: "lightText",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      fontSize: 14,
    },
  },
  buttons: {
    primary: {
      color: "white",
      bg: "blue",
      paddingTop: 6.5,
      paddingBottom: 6.5,
      textTransform: "uppercase",
      letterSpacing: "0.06rem",
      borderRadius: 32,
      outline: "none",
      ":hover": {
        cursor: "pointer",
      },
    },
    contact: {
      display: ["none", "flex"],
      marginLeft: 3,
      color: "white",
      bg: "blue",
      paddingTop: 16,
      paddingBottom: 16,
      textTransform: "uppercase",
      letterSpacing: "0.06rem",
      outline: "none",
      ":hover": {
        cursor: "pointer",
        color: "white",
        bg: "blueDarker",
      },
    },
    outline: {
      bg: "blue",
      ":hover": {
        color: "white",
        bg: "blueDarker",
      },
      paddingTop: 16,
      paddingBottom: 16,
      textTransform: "uppercase",
      letterSpacing: "0.06rem",
    },
    burger: {
      bg: "mainBackground",
      padding: "0",
      display: ["block", "none"],
      ":focus": {
        outline: "none",
      },
    },
  },
  forms: {
    input: {
      color: "mainForeground",
      fontFamily: "body",
      borderRadius: 2,
      borderColor: "secondaryForeground",
      marginBottom: "0.375rem",
      ":hover,:focus,.active": {
        borderColor: "secondaryForeground",
      },
    },
    select: {},
    textarea: {
      height: "10rem",
      color: "mainForeground",
      fontFamily: "body",
      borderRadius: 2,
      borderColor: "secondaryForeground",
      marginBottom: "0.375rem",
      ":hover,:focus,.active": {
        borderColor: "secondaryForeground",
      },
    },
    label: {
      fontSize: 12,
      color: "mainForeground",
      textTransform: "uppercase",
      fontFamily: "body",
      marginBottom: "0.125rem",
    },
    radio: {},
    checkbox: {},
  },
}
