import React, { useState } from "react"
import { Flex, Button } from "rebass"
import Link from "./Link"

export default function NavbarDropDown() {
  const [open, setOpen] = useState(false)

  return (
    <Flex
      pb={40}
      px={4}
      flexDirection="column"
      textAlign="left"
      backgroundColor="mainBackground"
      alignItems="center"
      sx={{ position: "fixed", top: 140, width: "100%", zIndex: 1000 }}
    >
      <Link onClick={() => setOpen(false)} py={3} to="/" variant="navDropdown">
        Work
      </Link>
      <Link
        onClick={() => setOpen(false)}
        py={3}
        to="/about"
        variant="navDropdown"
      >
        About
      </Link>
      <Link
        onClick={() => setOpen(false)}
        py={3}
        to="/contact"
        sx={{
          fontFamily: "body",
          fontSize: 3,
          textTransform: "uppercase",
          textDecoration: "none",
          color: "white",
          bg: "mainForeground",
          marginLeft: -16,
          marginRight: -16,
          alignSelf: "flex-start",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "mainForeground",
          borderRadius: 4,
          paddingTop: 16,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: 16,
          textTransform: "uppercase",
          letterSpacing: "0.06rem",
          outline: "none",
          ":hover": {
            cursor: "pointer",
            color: "mainForeground",
            bg: "white",
            borderColor: "mainForeground",
            borderWidth: "1px",
            borderStyle: "solid",
          },
        }}
      >
        Contact
      </Link>
      <a href="/contact" style={{ textDecoration: "none" }}>
        <Button variant="contact">Contact</Button>
      </a>
    </Flex>
  )
}
