import { ThemeProvider } from "emotion-theming"
import React from "react"
import { Global, css } from "@emotion/core"
import { Flex } from "rebass"
import Navbar from "./Navbar"
import theme from "../theme"
import Footer from "./Footer"
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          body {
            margin: 0;
            background: ${theme.colors.mainBackground};
          }
        `}
      />
      <Helmet>
        <meta name="p:domain_verify" content="62d2b26f1e35c343528470a37ed90dba"/>
        <title>Greg Woodward Photography</title>
      </Helmet>
      <Navbar />
      <Flex
        bg="mainBackground"
        flexDirection="column"
        sx={{ paddingX: [0, 6], marginTop: "160px" }}
      >
        {children}
        <Footer />
      </Flex>
    </ThemeProvider>
  )
}
