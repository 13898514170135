import React, { useState } from "react"
import { Flex, Heading, Button, Box, Text } from "rebass"
// import Link from "./Link"
import { Link } from "gatsby"

import MediaQuery from "react-responsive"
import NavbarDropDown from "./NavbarDropdown"

const CloseIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill={props.fill}
    height="24"
    viewBox="0 0 386.667 386.667"
  >
    <path d="M386.667 45.564L341.103 0l-147.77 147.769L45.564 0 0 45.564l147.769 147.769L0 341.103l45.564 45.564 147.769-147.769 147.769 147.769 45.564-45.564-147.768-147.77z" />
  </svg>
)

const BurgerIcon = props => (
  <svg
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 384 384"
  >
    <path d="M0 277.333h384V320H0zM0 170.667h384v42.667H0zM0 64h384v42.667H0z" />
  </svg>
)

const activeStyles = {
  borderBottom: "1px solid #303030", // Now it will work
}

export default function Navbar() {
  const [open, setOpen] = useState(false)

  console.log(activeStyles)

  return (
    <nav>
      <Flex flexDirection="column">
        <Flex
          justifyContent="space-between"
          backgroundColor="mainBackground"
          py={40}
          alignItems="center"
          sx={{
            paddingX: [4, 6],
            position: "fixed",
            top: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          <MediaQuery minDeviceWidth={768}>
            <a href="/" style={{ textDecoration: "none" }}>
              <Heading fontSize={5} as="h1" sx={{ textTransform: "uppercase" }}>
                Greg Woodward
              </Heading>
              <Text variant="navSmallprint">Photographer & Director</Text>
            </a>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={768}>
            <a href="/" style={{ textDecoration: "none" }}>
              <Heading fontSize={5} as="h1" sx={{ textTransform: "uppercase" }}>
                GW
              </Heading>
              <Text variant="navSmallprint">Photographer & Director</Text>
            </a>
          </MediaQuery>

          <Box mx="auto" />

          <MediaQuery minDeviceWidth={768}>
            <Flex flexDirection="row" alignItems="center">
              <Link
                to="/"
                variant="nav"
                style={{
                  display: ["none", "flex"],
                  paddingLeft: 4,
                  paddingRight: 4,
                  marginLeft: 16,
                  marginRight: 16,
                  fontFamily: "poppins, system-ui, sans-serif",
                  color: "#303030",
                  fontSize: 14,
                  textTransform: "uppercase",
                  textDecoration: "none",
                  ":hover,:focus,.active": {
                    color: "#303030",
                  },
                }}
                activeStyle={activeStyles}
              >
                Work
              </Link>
              <Link
                to="/about"
                variant="nav"
                style={{
                  display: ["none", "flex"],
                  paddingLeft: 4,
                  paddingRight: 4,
                  marginLeft: 16,
                  marginRight: 16,
                  fontFamily: "poppins, system-ui, sans-serif",
                  color: "#303030",
                  fontSize: 14,
                  textTransform: "uppercase",
                  textDecoration: "none",
                  ":hover,:focus,.active": {
                    color: "#303030",
                  },
                }}
                activeStyle={activeStyles}
                // sx={{
                //   letterSpacing: "0px",
                //   transition:
                //     "letter-spacing 380ms ease-in-out, transform 250ms ease-in-out",
                //   ":hover,:active": {
                //     letterSpacing: "4px",
                //   },
                // }}
              >
                About
              </Link>

              <Link
                to="/contact"
                variant="navBold"
                style={{
                  display: ["none", "flex"],
                  paddingLeft: 4,
                  paddingRight: 4,
                  marginLeft: 16,
                  marginRight: 16,
                  fontFamily: "poppins, system-ui, sans-serif",
                  fontWeight: 700,
                  color: "#303030",
                  fontSize: 14,
                  textTransform: "uppercase",
                  textDecoration: "none",
                  ":hover,:focus,.active": {
                    color: "#303030",
                  },
                }}
                activeStyle={activeStyles}

                // sx={{
                //   letterSpacing: "0px",
                //   transition:
                //     "letter-spacing 380ms ease-in-out, transform 250ms ease-in-out",
                //   ":hover,:active": {
                //     letterSpacing: "4px",
                //   },
                // }}
              >
                Contact
              </Link>
            </Flex>
          </MediaQuery>
          <Flex>
            <Button variant="burger" onClick={() => setOpen(!open)}>
              {open ? (
                <CloseIcon fill="#303030" />
              ) : (
                <BurgerIcon fill="#303030" />
              )}
            </Button>
          </Flex>
        </Flex>
        <MediaQuery maxDeviceWidth={768}>
          <Box display={!open && "none"}>
            <NavbarDropDown />
          </Box>
        </MediaQuery>
      </Flex>
    </nav>
  )
}
